@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

.expenses-card {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px rgb(50 42 42 /5%);
  flex-basis: calc(50% - 10px);
  position: relative;
}

.exp-title-amt {
  display: flex;
  align-items: center;
}

.exp-title-amt .amt {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 40px;
  height: 40px;
}

.exp-title {
  font-weight: bold;
  font-size: 18px;
}

.exp_message {
  display: block;
  align-items: center;
}

.exp-datetime {
  font-size: 12px;
  font-weight: 500;
  color: #44444496;
}

.all-payments-btn {
  border-radius: 0.25rem !important;
  color:white !important;
}
.categories-btn {
  border-radius: 0.25rem !important;
}

.all-payments-btn > button:hover{
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.chart-container .apexcharts-bar-series .apexcharts-bar[data\:index="5"] {
  stroke:  rgb(37 99 235 / var(--tw-bg-opacity)) !important;
  stroke-width: 2;
}
