.custom-grid-col{
  position: relative;
  height:75px;
}
.col-txt{
  position:absolute;
}
.fst-col{
  position: absolute;
  left:12px;
  bottom: 15px;
}
.second-col{
  position: absolute;
  bottom: 15px;
}

.tickets-card {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px rgb(50 42 42 /5%);
  flex-basis: calc(50% - 10px);
  position: relative;
}