@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@layer components {
  /* SideBar */
  .sidebar {
    @apply flex flex-col gap-y-4 h-screen bg-dark-grey p-2 pt-4 relative duration-300;
  }

  .sidebar__app-bar {
    @apply flex justify-center gap-4 items-center;
  }

  .sidebar__app-title {
    @apply text-white font-medium text-xl duration-300;
  }

  .sidebar__btn-close {
    @apply cursor-pointer duration-300 text-white justify-end;
  }

  .sidebar__btn-icon {
    @apply w-8 h-8;
  }

  .sidebar__app-logo {
    @apply cursor-pointer duration-300 text-white;
  }

  /* SideBar Nav */
  .nav {
    @apply flex justify-around;
  }

  .nav__item {
    @apply px-4 py-3 flex items-center gap-x-4 w-screen 
    rounded-md cursor-pointer 
    hover:bg-light-white
    text-white text-base;
  }

  .nav__icons {
    @apply cursor-pointer duration-300 text-white
    text-xl;
  }

  .nav__bottom {
    @apply flex flex-col justify-end h-screen;
  }

  .nav__msg {
    @apply flex bg-yellow-900 rounded-xl p-4 text-white;
  }

  /* .nav__p {
    @apply font-mono;
  } */

  /* CHATVIEW */
  .chatview {
    @apply flex flex-col h-full sm:max-h-[calc(100vh-10rem)]  duration-300 overflow-hidden relative dark:bg-light-grey;
}

  .chatview__chatarea {
    @apply flex-grow w-full mb-[5rem] overflow-y-scroll flex flex-col sm:mb-0;
  }

  .form {
    @apply flex fixed justify-between flex-col items-stretch sm:flex-row p-2  bottom-[5rem] left-0 right-0 sm:static z-10
    text-black dark:text-white duration-300;
  }

  .dropdown {
    @apply dark:bg-light-grey h-16 px-3 rounded-xl bg-light-white
     hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400
    dark:border-slate-500 focus:ring-offset-indigo-200 text-black
     dark:text-white transition  ease-in-out duration-300 text-center 
     text-base font-semibold shadow-md  focus:outline-none focus:ring-2
      focus:ring-offset-2;
  }

  .message__img {
    @apply rounded-lg shadow-lg bg-cover bg-gray-400 w-[512px] duration-300 ease-out;
  }

  .chatview__textarea-message {
    @apply bg-white ml-0 sm:ml-2 dark:bg-light-grey sm:w-full grow max-h-[20rem] min-h-[3rem]
     border-slate-400 dark:border-slate-500 mx-2
     /* focus:ring-offset-gray-700 focus:ring-2 focus:ring-offset-2  */
    resize-y rounded-xl;
    height:3.8rem;
    padding:1rem;
    resize: vertical; 
    overflow-y: auto; 
    font-size:16px;
  }
  .no-drag {
    resize: none;
    user-select: text;
  }
  
  .txt-area {
    position: relative;
    
  }
  @media (min-width: 1024px) {
    .txt-area {
      margin-left: -1rem;
    }
  }
  @media (max-width: 768px) {
    .txt-area {
      margin-left: 0.5rem;
    }
  }

  @media (min-width: 1024px) {
    .scroll-buttons {
      width: 8rem;
    }
  }
  @media (max-width: 768px) {
    .scroll-buttons {
      width: 26rem;
    }
  }
  .scroll-buttons > button:hover{
    background-color: white !important;
  }
  .suggestion{
    width:135px;
    margin-left: 2.75rem;
  }
  .microphoneicon {
    position: absolute;
    bottom: 29px;
    right: 115px;
    font-size: 30px;
    cursor: pointer;
  }

  .chatview__textarea-message:focus { 
    outline: none !important;
    /*box-shadow: 0 0 10px #7d8c9c;*/
}

  .chatview__btn-send {
    @apply pl-2 rounded-md  border-2 border-slate-400
    dark:border-slate-500 focus:ring-offset-indigo-200 text-black dark:text-white transition 
    ease-in-out duration-300 text-center text-base font-semibold shadow-md 
    focus:outline-none focus:ring-2 focus:ring-offset-2;
    background-color: rgba(44, 106, 207, 1) !important;
    color: white;
    height:3em;
    width:4em;
    margin:6px;
  }
  .sendicon {
    margin:0.5em;
  }

  .chatview__btn-send:disabled {
    @apply opacity-40 transition ease-in duration-300 text-center text-base font-semibold shadow-md;
  }

  /* ChatMessage */
  .message {
    @apply py-1 gap-2 flex items-start justify-end;
    align-items: center;
  }

  .message__wrapper {
    @apply flex-grow w-screen flex-wrap overflow-hidden;
  }

  .message__markdown {
    @apply text-xl text-slate-700 dark:text-slate-200;
    
  }

  .message__createdAt {
    @apply text-sm text-[.7rem] font-thin text-slate-600 dark:text-slate-300;
  }

  .message__pic {
    @apply h-8 w-8 bg-slate-200 mx-2 rounded-full flex items-center justify-center text-xl;
  }

  .message__thinking {
    @apply animate-pulse duration-1000 dark:text-white text-black text-xl;
  }

  /* Signin Page */

  .signin {
    @apply flex flex-col justify-center items-center h-screen w-screen overflow-hidden bg-dark-grey;
  }

  .signin__container {
    @apply border-4 rounded-2xl h-72 w-72 p-5 flex flex-col justify-center items-center;
  }

  .signin__btn {
    @apply h-12 px-4 rounded-xl bg-slate-100 hover:bg-slate-300 border
     border-light-grey focus:ring-offset-indigo-200 text-black transition 
    ease-in-out duration-300 text-center text-base font-semibold shadow-md 
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .signin__tos {
    @apply text-white text-center font-bold p-2;
  }
}

::-webkit-scrollbar {
  @apply bg-transparent w-3 h-3;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Use a light background */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3d3d3; /* Replace with Tailwind's light grey equivalent */
  border-radius: 0.75rem; /* This is equivalent to rounded-xl */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8; /* Darker shade for hover state */
}

/* Dark mode scrollbar */
@media (prefers-color-scheme: dark) {
  ::-webkit-scrollbar-thumb {
    background: #4a4a4a; /* Darker grey for dark mode */
  }
}

.ai-text {
  background-color: rgba(233, 233, 233, 1);
  /* width: fit-content; */
  padding: 10px;
  color: rgba(31, 31, 31, 1);
  border-radius: 20px 20px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  /* animation: 2s slide-right; */
  align-self: flex-start;
}
.user-text {
  background-color: rgba(237, 245, 255, 1);
  width: fit-content;
  padding: 10px;
  color: rgba(44, 106, 207, 1);
  border-radius: 20px 20px 0px 20px;
  font-size: 16px;
  font-weight: 400;
  /* animation: slide-left 2s; */
  align-self: flex-end;
}
.user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

p{
  animation-duration: 3s;
    animation-name: slidein;
    animation-direction: normal;
}

@keyframes slide-left {
    from {
      margin-left: 100%;
      /* width: 300%;  */
    }
  
    to {
      margin-left: 0%;
      /* width: 100%; */
    }
  }

  @keyframes slide-right {
    from {
      margin-left: -100%;
      /* width: 200%;  */
    }
  
    to {
      /* margin-left: 0%; */
      /* width: 100%; */
    }
  }

  html, body {margin: 0; height: 100%;}

.select .select__multi-value {
    --tw-bg-opacity: 1;
    border-radius: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    border: 1px solid rgb(37 99 235 / var(--tw-text-opacity));
    background: no-repeat;
}

.css-1oyaaxy-control {
  background: no-repeat !important;
  border:none !important;
}
.select-clear-indicator{
  display: none;
}
.select-dropdown-indicator{
  display: none;
}

.css-1oyaaxy-control:hover {
  background-color: white !important;
  border-color: rgb(37 99 235 / var(--tw-text-opacity)) !important;
  border-radius: 0.375rem !important;
  border-style: solid !important;
  border-width: 1px !important;

  .select-clear-indicator{
    display: flex;
  }
  .select-dropdown-indicator{
    display: flex;
  }
}

.css-r7b1ba-control {
  background-color: white !important;
  border-color: rgb(37 99 235 / var(--tw-text-opacity)) !important;
  border-radius: 0.375rem !important;
  border-style: solid !important;
  border-width: 1px !important;
  .select-clear-indicator{
    display: flex;
  }
  .select-dropdown-indicator{
    display: flex;
  }
}

.css-10dpb7w {
  margin:3px;
}

.chat-suggestions-card {
  border-width: 1px;
}

.custom-line{
  height: 1px !important;
}
.dialog-close-btn{
  height: 2.25rem !important;
}

 .ai-text ol{
  list-style-type: decimal;
  padding-left:1rem;
}
.ai-text ul{
  list-style-type: disc;
  padding-left:1rem;
} 

@keyframes expand-down {
  0% {
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
    opacity: 1;
  }
}

.animate-expand-down {
  animation: expand-down 0.9s ease-in-out;
}

@keyframes expandSlideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-expand-slide-up > div {
  transform: translateY(100%);
  opacity: 0;
  transition: .4s ease-in-out;
  animation: expandSlideUp .9s ease-in-out forwards; /* Apply the animation */
}
